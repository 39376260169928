import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import App from "./App";
import Home from "./Home";
import NotFound from "./NotFound";
import Shotu from "./shotu";

function MyRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/404" element={<NotFound />}/>
                <Route path="/:urlHash" element={<Shotu />} />
                {/* <Route path="/401"><NotFound /></Route> */}
            </Routes>
        </Router>
    )
  }

  export default MyRoutes;