import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { Alert, CircularProgress, Grid, Grid2, TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { Url } from '../Models/Url';
import { LoadingButton } from '@mui/lab';
import { Grid3x3Rounded } from '@mui/icons-material';
import { useState } from 'react';

export default function Hero() {
  const [longUrl, setLongUrl] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [shotu, setShotu] = React.useState<Url>();
  const [error, setError] = React.useState<string>();
  const [redirectUrl, setRedirectUrl] = React.useState<string>();
  const [rootAPI, setRootAPI] = React.useState<string>();
  const [rootUI, setRootUI] = React.useState<string>();
  const [isCopied, setIsCopied] = useState(false);

  React.useEffect(() => {
    if (process.env.NODE_ENV == 'development') {
      setRootAPI(process.env.REACT_APP_DEV_API_ROOT);
      setRootUI(process.env.REACT_APP_DEV_UI_ROOT);
    }
    else {
      setRootAPI(process.env.REACT_APP_PROD_API_ROOT);
      setRootUI(process.env.REACT_APP_PROD_UI_ROOT);
    }
  }, [])

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLongUrl(event.target.value);
  }

  const onButtonClick = () => {
    setShotu(undefined);
    setIsCopied(false);
    setError(undefined);
    setLoading(true);
  }

  async function copyShotuToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const onCopyButtonClick = () => {
    copyShotuToClipboard(`${rootUI}${shotu?.shotuUrl}`)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        // setTimeout(() => {
        //   setIsCopied(false);
        // }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onKeyDown = (event: { key: string; }) => {
    if (event.key == 'Backspace') {
      setShotu(undefined);
      setError(undefined);
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (longUrl) {
          if (rootAPI) {
            const obj = await axios.post<Url>(rootAPI, longUrl,
              {
                headers: {
                  'Content-Type': 'application/json', // Set the correct Content-Type
                }
              }
            );
            if (obj.status == 200) {
              setShotu(obj.data);
            }
            else
              setError(obj.statusText);
          }
        }
      }
      catch (err) {
        const axioserr = err as AxiosError;
        console.log(axioserr);
        setError(axioserr.message ? `An error occurred. Please try again. Error Code: ${axioserr.message}` : 'An error occurred. Please try again.');
      }
      finally {
        setLoading(false);
      }
    }
    fetchData();

  }, [loading])

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',

        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            make&nbsp;loooong urls&nbsp;     
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              small
          </Typography>       
          </Typography>
          
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            with&nbsp;     
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              shotu.live
          </Typography>       
          </Typography>
          
          <Stack
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: '700px' } }}
          >
            <TextField
              id="long-url-hero"
              hiddenLabel
              size="medium"
              variant="outlined"
              aria-label="Enter long url"
              placeholder="Paste long url"
              onChange={onTextChange}
              onKeyDown={onKeyDown}
              value={longUrl}
              multiline
              maxRows={10}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: 20
                }
              }}
              slotProps={{
                htmlInput: {
                  autoComplete: 'off',
                  'aria-label': 'Paste long url',
                  minWidth: 4100,
                },
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              {loading && <CircularProgress size='30px' />}
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                sx={{ minWidth: 'fit-content' }}
                onClick={onButtonClick}
              >
                Create Shotu
              </Button>
            </Stack>
          </Stack>          
            {shotu && shotu.shotuUrl &&
              <Stack
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: '100%', sm: '400px' } }}
              >
                <Button 
                  variant='outlined' 
                  color='primary'
                  href={`${rootUI}${shotu.shotuUrl}`}
                  sx={{
                    textTransform:'none',
                    fontSize: 20,
                    fontWeight:'medium'
                  }}>
                  {`${rootUI}${shotu.shotuUrl}`}
                </Button>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    sx={{ minWidth: 'fit-content' }}
                    onClick={onCopyButtonClick}
                  >
                  {isCopied ? 'Copied' : 'Copy Shotu'}
                </Button>
                </Stack>
              </Stack>
            }
            {error &&
              <Alert severity='error'>
                {error}
              </Alert>
            }
          </Stack>
      </Container>
    </Box>
  );
}