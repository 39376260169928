
import { Helmet } from 'react-helmet';
import MyRoutes from './Routing';

function App() {
  return (
    <div className="App"> 
      <MyRoutes />      
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
    </div>
  );
}

export default App;
