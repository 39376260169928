import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './NavBar';
import { Button } from '@mui/material';
import AppAppBar from './AppAppBar';
import MarketingPage from './FrontPage';

function Home() {
    return (
        <div>   
        <MarketingPage />      
        </div>
      );
    }

export default Home;